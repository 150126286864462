import React, { useEffect, useState } from 'react';
import { firestore } from '../Firebase';
import { Link } from 'react-router-dom';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';

import Header from './Header';

const Picklists = () => {
  const [picklistDocs, setPicklistDocs] = useState([]);

  useEffect(() => {
    firestore.collection('picklist').onSnapshot((snapshot) => {
      const picklists = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPicklistDocs(picklists);
    });
  }, []);

  return (
    <Container fluid>
      <Header />

      <Row>
        <Col>
          <div className="h1">Picklists</div>
          <ListGroup>
            {picklistDocs &&
              picklistDocs.map((option, index) => (
                <ListGroup.Item
                  action
                  as={Link}
                  to={`/picklist/${option.id}`}
                  key={index}
                >
                  {option.picklistName}
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default Picklists;
