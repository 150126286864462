import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { firestore, storage } from '../Firebase';
import toast from 'react-hot-toast';
import 'react-awesome-lightbox/build/style.css';
import Lightbox from 'react-awesome-lightbox';

const ItemImages = ({ itemID, loading, setLoading }) => {
  const [images, setImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchImages(itemID);
  }, [itemID]);

  const fetchImages = async (id) => {
    try {
      firestore
        .collection('items')
        .doc(id)
        .collection('images')
        .orderBy('date_added', 'asc')
        .onSnapshot((snapshot) => {
          const imagesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setImages(imagesData);
        });
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleDeleteImage = async (imageId, imageUrl) => {
    try {
      setLoading(true);

      await firestore
        .collection('items')
        .doc(itemID)
        .collection('images')
        .doc(imageId)
        .delete();

      await storage.refFromURL(imageUrl).delete();

      setImages(images.filter((image) => image.id !== imageId));

      setLoading(false);
      toast.success('Image deleted successfully.');
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('An error occurred while deleting the image.');
    }
  };

  return (
    <Row>
      {lightboxOpen && (
        <Lightbox
          images={images.map((image) => ({ url: image.url }))}
          startIndex={images.findIndex((image) => image.url === selectedImage)}
          onClose={() => setLightboxOpen(false)}
        />
      )}

      {images.map((image, index) => (
        <Col key={index}>
          <img
            src={image.url}
            alt={`File ${index}`}
            className="w-100"
            onClick={() => {
              setSelectedImage(image.url);
              setLightboxOpen(true);
            }}
            style={{ cursor: 'pointer' }}
          />
          <div className="caption">{image.date_added}</div>

          <a
            href={image.url}
            type="button"
            className="btn btn-primary me-2"
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeleteImage(image.id, image.url)}
          >
            Delete
          </button>
        </Col>
      ))}
    </Row>
  );
};

export default ItemImages;
