import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: 'react9zem.firebaseapp.com',
  projectId: 'react9zem',
  storageBucket: 'react9zem.appspot.com',
  messagingSenderId: '674921275085',
  appId: '1:674921275085:web:7d7d6a468c6e8ab31c1384',
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
