import React, { useEffect, useState } from 'react';
import { firestore } from '../Firebase';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import ItemImages from './ItemImages';
import DownloadCSV from './DownloadCSV';
import Header from './Header';

const Picklist = () => {
  const { picklistID } = useParams();
  const [picklistPicks, setPicklistPicks] = useState();
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [updatedFields, setUpdatedFields] = useState({});
  const [sortBy, setSortBy] = useState({ field: 'date_added', order: 'asc' });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const setLoadingState = (isLoading) => setLoading(isLoading);
  const tableHeaders = [
    'Name',
    'Manufacturer',
    'Bin Number',
    'Category',
    'Description',
    'Serial',
    'Fair Market Value',
    'Date Purchased',
    'Condition',
    'Location',
    'Date Added',
  ];

  useEffect(() => {
    if (items.length > 0) {
      // Format items data for CSV
      const formattedData = items.map((item) => ({
        Name: item.name,
        Manufacturer: item.manufacturer,
        'Bin Number': item.bin_number,
        Category: item.category,
        Description: item.description,
        Serial: item.serial,
        'Fair Market Value': item.fair_market_value,
        'Date Purchased': item.date_purchased,
        Condition: item.condition,
        Location: item.location,
        'Date Added': item.date_added,
      }));
      setTableData(formattedData);
    }
  }, [items]);

  const handleShowModal = (itemID) => {
    setSelectedItemID(itemID);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const picklistRef = firestore
      .collection('picklist')
      .doc(picklistID)
      .collection('picks');

    picklistRef
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPicklistPicks(docs);
        }
      })
      .catch((error) => {
        console.error('Error getting subcollection:', error);
      });
  }, [picklistID]);

  useEffect(() => {
    if (picklistID !== '' && picklistPicks) {
      Promise.all(
        picklistPicks.map((pick) =>
          firestore.collection('items').doc(pick.id).get()
        )
      ).then((docs) => {
        const firestoreItems = docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedItems = firestoreItems.sort((a, b) => {
          const fieldA = a[sortBy.field];
          const fieldB = b[sortBy.field];

          if (sortBy.order === 'asc') {
            return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
          } else {
            return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
          }
        });

        setItems(sortedItems);
      });
    }
  }, [picklistID, picklistPicks, sortBy]);

  const handleUpdate = (newValue, field, itemID) => {
    firestore
      .collection('items')
      .doc(itemID)
      .update({
        [field]: newValue,
      })
      .then(() => {
        setUpdatedFields((prevFields) => ({
          ...prevFields,
          [itemID]: { ...prevFields[itemID], [field]: true },
        }));
        setTimeout(() => {
          setUpdatedFields((prevFields) => ({
            ...prevFields,
            [itemID]: { ...prevFields[itemID], [field]: false },
          }));
        }, 100);
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  };

  const handleSortBy = (field) => {
    setSortBy((prevSortBy) => ({
      field,
      order:
        prevSortBy.field === field && prevSortBy.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  return (
    <Container fluid>
      <Header />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemImages
            itemID={selectedItemID}
            loading={loading}
            setLoading={setLoadingState}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          <div className="h1">Items</div>

          <DownloadCSV
            data={tableData}
            filename={picklistID}
            headers={tableHeaders}
          />

          <table id="csvTable" className="table table-striped table-hover">
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#fff',
              }}
            >
              <tr>
                <th></th>
                <th></th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('name')}
                >
                  Name
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('manufacturer')}
                >
                  Manufacturer
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('bin_number')}
                >
                  Bin Number
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('category')}
                >
                  Category
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('description')}
                >
                  Description
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('serial')}
                >
                  Serial
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('fair market value')}
                >
                  Fair Market Value
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('date_purchased')}
                >
                  Date Purchased
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('condition')}
                >
                  Condition
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('name')}
                >
                  Location
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortBy('date_added')}
                >
                  Date Added
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 &&
                items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={`/edit/${item.id}`}
                        className="btn btn-warning me-2 editBtn"
                      >
                        Open
                      </Link>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-primary me-3"
                          onClick={() => handleShowModal(item.id)}
                        >
                          Images
                        </button>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.name &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.name}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'name', item.id);
                          }
                        }}
                        style={{ width: '20em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.manufacturer &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.manufacturer}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(
                              e.target.value,
                              'manufacturer',
                              item.id
                            );
                          }
                        }}
                        style={{ width: '10em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.bin_number &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.bin_number}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'bin_number', item.id);
                          }
                        }}
                        style={{ width: '4em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.category &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.category}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'category', item.id);
                          }
                        }}
                        style={{ width: '10em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.description &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.description}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(
                              e.target.value,
                              'description',
                              item.id
                            );
                          }
                        }}
                        style={{ width: '20em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.serial &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.serial}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'serial', item.id);
                          }
                        }}
                        style={{ width: '10em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.fair_market_value &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.fair_market_value}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(
                              e.target.value,
                              'fair_market_value',
                              item.id
                            );
                          }
                        }}
                        style={{ width: '7em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.date_purchased &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.date_purchased}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(
                              e.target.value,
                              'date_purchased',
                              item.id
                            );
                          }
                        }}
                        style={{ width: '8em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.condition &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.condition}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'condition', item.id);
                          }
                        }}
                        style={{ width: '10em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${
                          updatedFields[item.id]?.location &&
                          'border border-success border-2'
                        }`}
                        defaultValue={item.location}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            handleUpdate(e.target.value, 'location', item.id);
                          }
                        }}
                        style={{ width: '10em' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={item.date_added}
                        style={{ width: '12em' }}
                      />
                    </td>
                  </tr>
                ))}
              {items.length === 0 && (
                <tr>
                  <td colSpan="12">No items found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default Picklist;
