import React, { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone({ onDropHandler, files, setFiles }) {
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { 'image/*': [] },
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      onDropHandler(acceptedFiles);
    },
  });

  const style = useMemo(
    () =>
      [
        'baseStyle',
        isFocused ? 'focusedStyle' : '',
        isDragAccept ? 'acceptStyle' : '',
        isDragReject ? 'rejectStyle' : '',
      ].join(' '),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          alt="uploaded"
          src={file.preview}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <section className="dzContainer">
      <div {...getRootProps()} className={style}>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <button type="button" className="btn btn-primary" onClick={open}>
          Upload File
        </button>
      </div>
      <aside className="thumbsContainer">{thumbs}</aside>
    </section>
  );
}

export default Dropzone;
