import React, { useEffect, useState } from 'react';
import { firestore } from '../Firebase';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ItemImages from './ItemImages';
import moment from 'moment-timezone';
import toast, { Toaster } from 'react-hot-toast';
import Header from './Header';

const List = () => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [updatedFields, setUpdatedFields] = useState({});
  const [sortBy, setSortBy] = useState({ field: 'date_added', order: 'asc' });
  const [checkedItems, setCheckedItems] = useState([]);
  const [picklistName, setPicklistName] = useState('');

  const [loading, setLoading] = useState(false);
  const setLoadingState = (isLoading) => setLoading(isLoading);

  const handleShowModal = (itemID) => {
    setSelectedItemID(itemID);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    firestore
      .collection('items')
      .where('active', '==', true)
      .orderBy(sortBy.field, sortBy.order)
      .onSnapshot((snapshot) => {
        const firestoreItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItems(firestoreItems);
      });
  }, [sortBy]);

  const handleUpdateCheckbox = async (itemID, newValue) => {
    try {
      await firestore
        .collection('items')
        .doc(itemID)
        .update({ active: newValue });
      if (newValue) {
        toast.success('Item activated');
      } else {
        toast.success('Item deactivated');
      }
    } catch (error) {
      console.error('Error updating checkbox:', error);
      toast.error('Failed to update checkbox');
    }
  };

  const handleCheckboxChange = (itemID, newValue) => {
    setCheckedItems((prevCheckedItems) => {
      if (newValue) {
        return [...prevCheckedItems, itemID];
      } else {
        return prevCheckedItems.filter((id) => id !== itemID);
      }
    });
    handleUpdateCheckbox(itemID, newValue);
  };

  const handleUpdate = (newValue, field, itemID) => {
    firestore
      .collection('items')
      .doc(itemID)
      .update({
        [field]: newValue,
      })
      .then(() => {
        setUpdatedFields((prevFields) => ({
          ...prevFields,
          [itemID]: { ...prevFields[itemID], [field]: true },
        }));
        setTimeout(() => {
          setUpdatedFields((prevFields) => ({
            ...prevFields,
            [itemID]: { ...prevFields[itemID], [field]: false },
          }));
        }, 100);
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  };

  const handleSort = (field) => {
    setSortBy((prevSortBy) => ({
      field,
      order:
        prevSortBy.field === field && prevSortBy.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const handleCheckbox = (id) => {
    setCheckedItems((prevItems) => {
      const index = prevItems.indexOf(id);
      if (index === -1) {
        return [...prevItems, id];
      } else {
        return prevItems.filter((item) => item !== id);
      }
    });
  };

  const handleExportPicklist = () => {
    if (picklistName.length === 0) {
      toast.error('Please enter a picklist name.');
      return;
    }

    if (checkedItems.length === 0) {
      toast.error('Please select at least one item to export.');
      return;
    }

    const itemDocRef = firestore.collection('picklist').doc();

    const pickListItems = itemDocRef.collection('picks');

    checkedItems.forEach(async (id) => {
      await pickListItems.add({
        id: id,
      });
    });

    let nowTime = moment();
    let formattedDate = nowTime
      .tz('America/Los_Angeles')
      .format('YYYY-MM-DD_HH:mm:ss');

    itemDocRef.set({ date_added: formattedDate, picklistName: picklistName });

    setCheckedItems((prevCheckedItems) => []);
    setPicklistName('');
    toast.success('Picklist created successfully');
  };

  return (
    <>
      <Header />
      <Toaster />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedItemID
              ? items.find((item) => item.id === selectedItemID)?.name +
                ' Images'
              : 'Item Images'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemImages
            itemID={selectedItemID}
            loading={loading}
            setLoading={setLoadingState}
          />
        </Modal.Body>
      </Modal>

      <div className="h1">Items</div>

      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          style={{ width: '16em' }}
          value={picklistName}
          onChange={(e) => setPicklistName(e.target.value)}
        />
        <button
          className="btn btn-primary"
          onClick={() => handleExportPicklist()}
        >
          Export new picklist
        </button>
      </div>

      <table id="csvTable" className="table table-striped table-hover">
        <thead
          style={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}
        >
          <tr>
            <th></th>
            <th></th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('name')}
            >
              Name
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('manufacturer')}
            >
              Manufacturer
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('bin_number')}
            >
              Bin Number
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('notes')}
            >
              Notes
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('category')}
            >
              Category
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('description')}
            >
              Description
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('serial')}
            >
              Serial
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('fair_market_value')}
            >
              Fair Market Value
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('date_purchased')}
            >
              Date Purchased
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('condition')}
            >
              Condition
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('location')}
            >
              Location
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('date_added')}
            >
              Date Added
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSort('active')}
            >
              Active
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                <Link
                  to={`/edit/${item.id}`}
                  className="btn btn-warning me-2 editBtn"
                >
                  Open
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary me-3"
                    onClick={() => handleShowModal(item.id)}
                  >
                    Images
                  </button>
                  <div className="form-check">
                    <input
                      checked={checkedItems.includes(item.id)}
                      onChange={() => handleCheckbox(item.id)}
                      className="form-check-input"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      name={item.id}
                    />
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.name &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'name', item.id);
                    }
                  }}
                  style={{ width: '20em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.manufacturer &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.manufacturer}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'manufacturer', item.id);
                    }
                  }}
                  style={{ width: '10em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.bin_number &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.bin_number}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'bin_number', item.id);
                    }
                  }}
                  style={{ width: '4em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.notes &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.notes}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'notes', item.id);
                    }
                  }}
                  style={{ width: '20em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.category &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.category}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'category', item.id);
                    }
                  }}
                  style={{ width: '10em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.description &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.description}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'description', item.id);
                    }
                  }}
                  style={{ width: '20em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.serial &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.serial}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'serial', item.id);
                    }
                  }}
                  style={{ width: '10em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.fair_market_value &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.fair_market_value}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(
                        e.target.value,
                        'fair_market_value',
                        item.id
                      );
                    }
                  }}
                  style={{ width: '7em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.date_purchased &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.date_purchased}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'date_purchased', item.id);
                    }
                  }}
                  style={{ width: '8em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.condition &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.condition}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'condition', item.id);
                    }
                  }}
                  style={{ width: '10em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className={`form-control ${
                    updatedFields[item.id]?.location &&
                    'border border-success border-2'
                  }`}
                  defaultValue={item.location}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      handleUpdate(e.target.value, 'location', item.id);
                    }
                  }}
                  style={{ width: '10em' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={item.date_added}
                  style={{ width: '12em' }}
                  readOnly
                />
              </td>
              <td>
                <div className="form-check">
                  <input
                    checked={item.active}
                    onChange={(e) =>
                      handleCheckboxChange(item.id, e.target.checked)
                    }
                    className="form-check-input"
                    style={{ transform: 'scale(1.5)' }}
                    type="checkbox"
                    name={item.id.toString()}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default List;
