import './App.css';
import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Add from './components/Add';
import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Edit from './components/Edit';
import Picklist from './components/Picklist';
import Picklists from './components/Picklists';
import Inactives from './components/Inactives';
import { AuthContext } from './components/AuthContext';
import LoginComponent from './components/LoginComponent';

function App() {
  const { user } = useContext(AuthContext) || {};

  return (
    <>
      {user ? (
        <Routes>
          <Route path="/add" element={<Add />}></Route>
          <Route path="/" element={<List />}></Route>
          <Route path="/inactives" element={<Inactives />}></Route>
          <Route path="/picklists" element={<Picklists />}></Route>
          <Route path="/picklist/:picklistID" element={<Picklist />}></Route>
          <Route path="/edit/:itemID" element={<Edit />}></Route>
        </Routes>
      ) : (
        <LoginComponent />
      )}
    </>
  );
}

export default App;
