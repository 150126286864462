import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { firestore, storage } from '../Firebase';
import moment from 'moment-timezone';
import Resizer from 'react-image-file-resizer';

function UploadMoreImages({ itemID }) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onDropHandler = async (acceptedFiles) => {
    const storageRef = storage.ref();
    const itemRef = firestore
      .collection('items')
      .doc(itemID)
      .collection('images');

    acceptedFiles.forEach(async (file) => {
      let nowTime = moment();
      let formattedDate = nowTime
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD_HH:mm:ss');

      const filenameParts = file.name.split('.');
      const filenameWithoutExtension = filenameParts.join('.');
      const renamedFilename = `${filenameWithoutExtension}_${formattedDate}.jpg`;

      Resizer.imageFileResizer(
        file,
        1600,
        1600,
        'JPEG',
        80,
        0,
        async (resizedFile) => {
          const fileRef = storageRef.child(renamedFilename);
          await fileRef.put(resizedFile);
          const downloadURL = await fileRef.getDownloadURL();
          await itemRef.add({ url: downloadURL, date_added: formattedDate });
        },
        'file'
      );
    });
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { 'image/*': [] },
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      onDropHandler(acceptedFiles);
    },
  });

  const style = useMemo(
    () =>
      [
        'baseStyle',
        isFocused ? 'focusedStyle' : '',
        isDragAccept ? 'acceptStyle' : '',
        isDragReject ? 'rejectStyle' : '',
      ].join(' '),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          alt="uploaded"
          src={file.preview}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <section className="dzContainer">
      <div {...getRootProps()} className={style}>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <button type="button" className="btn btn-primary" onClick={open}>
          Upload File
        </button>
      </div>
      <aside className="thumbsContainer">{thumbs}</aside>
    </section>
  );
}

export default UploadMoreImages;
