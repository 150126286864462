import React, { createContext, useEffect, useState } from 'react';
import { auth } from '../Firebase';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  const login = (password) => {
    const hardcodedPassword = 'wzwz123';

    if (password === hardcodedPassword) {
      auth
        .setPersistence('local')
        .then(() => {
          return auth.signInAnonymously();
        })
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setError('Incorrect password');
    }
  };

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        setUser(null);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
