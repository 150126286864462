import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore } from '../Firebase';
import { BeatLoader } from 'react-spinners';
import { Col, Container, Row } from 'react-bootstrap';
import UploadMoreImages from './UploadMoreImages';
import ItemImages from './ItemImages';

const Edit = () => {
  const [loading, setLoading] = useState(false);
  const { itemID } = useParams();
  const initialState = {
    id: itemID,
    name: '',
    manufacturer: '',
    category: '',
    description: '',
    serial: '',
    fair_market_value: '',
    date_purchased: '',
    condition: '',
    bin_number: '',
    location: '',
  };
  const [item, setItem] = useState(initialState);

  const navigate = useNavigate();

  useEffect(() => {
    fetchItemData(itemID);
  }, [itemID]);

  const fetchItemData = async (id) => {
    try {
      const itemRef = firestore.collection('items').doc(id);
      const doc = await itemRef.get();
      if (doc.exists) {
        setItem(doc.data());
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((itemArgument) => ({
      ...itemArgument,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const updatedItem = { ...item };

      const itemRef = firestore.collection('items').doc(itemID);
      await itemRef.update(updatedItem);

      setLoading(false);
      toast.success('Item updated successfully.');
      navigate('/');
    } catch (error) {
      console.error('Error updating item:', error);
      const errorMessage = error.message || 'An error occurred.';
      toast.error(errorMessage);
    }
  };

  return (
    <Container>
      <Row>
        <Col className="col-sm-6 offset-sm-3">
          <Toaster />
          {loading && (
            <div className="overlay">
              <BeatLoader
                color="#36d7b7"
                size={15}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <button
            className="btn btn-secondary mb-3 mt-1"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h2>Edit Item</h2>
          <form onSubmit={handleSubmit}>
            <UploadMoreImages itemID={itemID} />
            <ItemImages
              itemID={itemID}
              loading={loading}
              setLoading={setLoading}
            />

            <div className="form-floating my-3">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="name"
                onChange={handleChange}
                value={item.name}
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="manufacturer"
                placeholder="manufacturer"
                onChange={handleChange}
                value={item.manufacturer}
              />
              <label htmlFor="manufacturer">Manufacturer</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="category"
                placeholder="category"
                onChange={handleChange}
                value={item.category}
              />
              <label htmlFor="category">Category</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="description"
                placeholder="description"
                onChange={handleChange}
                value={item.description}
              />
              <label htmlFor="description">Description</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="serial"
                placeholder="serial"
                onChange={handleChange}
                value={item.serial}
              />
              <label htmlFor="serial">Serial</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="fair_market_value"
                placeholder="fair_market_value"
                onChange={handleChange}
                value={item.fair_market_value}
              />
              <label htmlFor="fair_market_value">Fair Market Value</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="date_purchased"
                placeholder="date_purchased"
                onChange={handleChange}
                value={item.date_purchased}
              />
              <label htmlFor="date_purchased">Date Purchased</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="condition"
                placeholder="condition"
                onChange={handleChange}
                value={item.condition}
              />
              <label htmlFor="condition">Condition</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="bin_number"
                placeholder="bin_number"
                onChange={handleChange}
                value={item.bin_number}
              />
              <label htmlFor="bin_number">Bin Number</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="location"
                placeholder="location"
                onChange={handleChange}
                value={item.location}
              />
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Edit;
