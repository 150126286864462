import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Header = ({ name }) => {
  const { logout } = useContext(AuthContext) || {};

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/">List</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/picklists">
              Picklists
            </Nav.Link>
            <Nav.Link as={Link} to="/add">
              Add
            </Nav.Link>
            <Nav.Link as={Link} to="/inactives">
              Inactive Items
            </Nav.Link>
            <button className="btn btn-primary" onClick={logout}>
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
