import React, { useEffect, useState } from 'react';

const DownloadCSV = ({ data, filename, headers }) => {
  const [isVisible, setIsVisible] = useState(false);

  const downloadCSV = (csv, filename) => {
    const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportDataToCSV = (data, filename) => {
    // Include headers
    const csv = [headers.join(',')];
    // Convert data to CSV format
    data.forEach((row) => {
      const rowData = headers.map((header) => row[header]);
      csv.push(rowData.join(','));
    });
    // Download CSV
    downloadCSV(csv.join('\n'), filename);
  };

  useEffect(() => {
    if (data.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [data]);

  return (
    <div className="text-end">
      {isVisible && (
        <button
          className="btn btn-primary csvDownload"
          onClick={() => exportDataToCSV(data, filename)}
        >
          Download CSV
        </button>
      )}
    </div>
  );
};

export default DownloadCSV;
