import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { firestore, storage } from '../Firebase';
import Dropzone from './Dropzone';
import { BeatLoader } from 'react-spinners';
import moment from 'moment-timezone';
import Resizer from 'react-image-file-resizer';
import Header from './Header';

const Add = () => {
  const [item, setItem] = useState({
    photo: '',
    name: '',
    manufacturer: '',
    notes: '',
    category: '',
    description: '',
    serial: '',
    fair_market_value: '',
    date_purchased: '',
    condition: '',
    bin_number: '',
    location: '',
    active: true,
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (uploadedFiles.length === 0) {
      toast.error('Please submit an image file.');
      return;
    }

    setLoading(true);

    try {
      let nowTime = moment();
      let formattedDate = nowTime
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD_HH:mm:ss');

      const itemData = {
        name: item.name,
        manufacturer: item.manufacturer,
        bin_number: item.bin_number,
        notes: item.notes,
        category: item.category,
        description: item.description,
        serial: item.serial,
        fair_market_value: item.fair_market_value,
        date_purchased: item.date_purchased,
        condition: item.condition,
        location: item.location,
        active: true,
        date_added: formattedDate,
      };

      const uploadedImages = await Promise.all(
        uploadedFiles.map(async (file) => {
          const downloadURL = await handleFileUpload(file, formattedDate);
          return { url: downloadURL, date_added: formattedDate };
        })
      );

      if (uploadedImages.some((image) => !image.date_added)) {
        throw new Error('Some images have undefined date_added field.');
      }

      const itemDocRef = await firestore.collection('items').add(itemData);

      const imageSubcollectionRef = itemDocRef.collection('images');

      uploadedImages.forEach(async (image) => {
        await imageSubcollectionRef.add({
          url: image.url,
          date_added: image.date_added,
        });
      });

      setItem({
        name: '',
        manufacturer: '',
        bin_number: '',
        notes: '',
        category: '',
        description: '',
        serial: '',
        fair_market_value: '',
        date_purchased: '',
        condition: '',
        location: '',
      });
      setUploadedFiles([]);
      toast.success(item.name + ' added successfully.');
      setFiles([]);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('An error occurred while adding the item. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file, formattedDate) => {
    try {
      const storageRef = storage.ref();

      const filenameParts = file.name.split('.');
      const fileExtension = filenameParts.pop();
      const filenameWithoutExtension = filenameParts.join('.');
      const renamedFilename = `${filenameWithoutExtension}_${formattedDate}.${fileExtension}`;

      const resizedFile = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1600,
          1600,
          'JPEG',
          80,
          0,
          (resizedImage) => {
            resolve(resizedImage);
          },
          'file'
        );
      });

      const fileRef = storageRef.child(renamedFilename);
      await fileRef.put(resizedFile);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error(
        'An error occurred while uploading the file. Please try again.'
      );
      throw error;
    }
  };

  return (
    <Container fluid>
      <Header />
      <Row>
        <Col className="col-sm-6 offset-sm-3">
          {loading && (
            <div className="overlay">
              <BeatLoader
                color="#36d7b7"
                size={15}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}

          <h2 className="pt-2">Add Item</h2>
          <form onSubmit={handleSubmit}>
            <Dropzone
              onDropHandler={setUploadedFiles}
              files={files}
              setFiles={setFiles}
            />

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="name"
                onChange={handleChange}
                value={item.name}
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="manufacturer"
                placeholder="manufacturer"
                onChange={handleChange}
                value={item.manufacturer}
              />
              <label htmlFor="manufacturer">Manufacturer</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="bin_number"
                placeholder="bin_number"
                onChange={handleChange}
                value={item.bin_number}
              />
              <label htmlFor="bin_number">Bin Number</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="notes"
                placeholder="notes"
                onChange={handleChange}
                value={item.notes}
              />
              <label htmlFor="notes">Notes</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="category"
                placeholder="category"
                onChange={handleChange}
                value={item.category}
              />
              <label htmlFor="category">Category</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="description"
                placeholder="description"
                onChange={handleChange}
                value={item.description}
              />
              <label htmlFor="description">Description</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="serial"
                placeholder="serial"
                onChange={handleChange}
                value={item.serial}
              />
              <label htmlFor="serial">Serial</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="fair_market_value"
                placeholder="fair_market_value"
                onChange={handleChange}
                value={item.fair_market_value}
              />
              <label htmlFor="fair_market_value">Fair Market Value</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="date_purchased"
                placeholder="date_purchased"
                onChange={handleChange}
                value={item.date_purchased}
              />
              <label htmlFor="date_purchased">Date Purchased</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="condition"
                placeholder="condition"
                onChange={handleChange}
                value={item.condition}
              />
              <label htmlFor="condition">Condition</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="location"
                placeholder="location"
                onChange={handleChange}
                value={item.location}
              />
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-12 mb-5">
              <input type="submit" className="btn btn-primary mb-5" />
              <Toaster />
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Add;
