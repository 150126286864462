import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from './AuthContext';
import { Col, Container, Row } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';

const LoginComponent = () => {
  const { login, error } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const focusInputRef = useRef(null);

  useEffect(() => {
    focusInputRef.current.focus();
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    login(password);
  };

  return (
    <Container fluid>
      <Row>
        <Toaster />
        <Col
          className="text-center"
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="p-2">
            <h6 className="h5">Log In</h6>
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-floating mb-3">
              <input
                id="password"
                className="form-control"
                type="password"
                placeholder="Password"
                value={password}
                ref={focusInputRef}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="password">Password</label>
            </div>
            <button type="submit" className="btn btn-primary">
              Sign in
            </button>
          </form>
          {error && <p>{error}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginComponent;
